'use strict';

import HomeView from './views/home';
import InfoView from './views/info';
import LoginView from './views/login';
import ForgotPasswordView from './views/forgot_password';
import ChangePasswordView from './views/change_password';
import RegisterView from './views/register';
import CoachQuestionView from './views/coach_question';
import ChatCoachQuestionView from './views/chat_coach_question';
import ShortLibraScoreView from './views/short_libra_score';
import SnippetView from './views/snippet';
import ThemesView from './views/themes';
import ThemeDetailView from './views/theme_detail';
import ApprovePrivacyPolicyView from './views/approve_privacy_policy';

import PageModel from './models/page';
import CareVariablesModel from './models/care_variables';
import InfoMenuItemCollection from './collections/info_menu_items';

import Session from './utils/session';
import * as Dialogs from './utils/dialogs';
import QuestionManager from './utils/questionmanager';

import App from './app';

export default {
  // Home
  home: function () {
    if (Session.isLoggedIn && !Session.isAnonymous) {
      App.router.navigate('#snippet', {trigger: true});
    } else {
      App.goto(new HomeView({
        model: new PageModel({id: 'home'})
      }));
    }
  },

  // Info page
  info: function () {
    App.goto(new InfoView({
      collection: new InfoMenuItemCollection()
    }));
  },

  // Login
  login: function () {
    if (Session.isLoggedIn && !Session.isAnonymous) {
      App.router.navigate('#snippet', {trigger: true});
    } else if (App.kioskMode) {
      App.router.navigate('#home', {trigger: true});
    } else {
      App.goto(new LoginView());
    }
  },

  // Logout
  logout: function () {
    App.isBusy(true);

    Session.logout().then(
      function () {
        App.clearCache();
        Dialogs.alert('Je bent nu uitgelogd.');
        App.router.navigate('', {trigger: true});
      }
    ).then(function () {
      App.isBusy(false);
    });
  },

  // Forgot my password
  forgotPassword: function () {
    App.goto(new ForgotPasswordView());
  },

  // Change password
  changePassword: function (required) {
    App.goto(new ChangePasswordView({changeRequired: required == '1'}));
  },

  approvePrivacyPolicy: function () {
    App.goto(new ApprovePrivacyPolicyView());
  },

  // User registration
  register: function () {
    App.goto(new RegisterView());
  },

  // Nut of the day
  snippet: function () {
    App.goto(new SnippetView());
  },

  // Start-up short (anonymous) LIBRA test
  shortLibra: function () {
    let startShortLibra = function (userId) {
      // Get an active session
      Session.request('GET', 'coach/get_active_sessions', {'userId': userId})
        .done(function (data, status, xhr) {
          if (data.length > 0) {
            App.isBusy(false);
            App.router.navigate('#session/' + data[0].id, {trigger: true});
          } else {
            Dialogs.alert('Geen actieve sessies gevonden.');
            App.isBusy(false);
          }
        })
        .fail(function (xhr, status, error) {
          Dialogs.alert('Kan geen actieve sessie ophalen.');
          App.isBusy(false);
        });
    };

    if (Session.isLoggedIn && !Session.isAnonymous) {
      Dialogs.alert('De korte LIBRA test is niet beschikbaar wanneer je ingelogd bent.');
    } else {
      App.isBusy(true);
      // Get an anonymous userId
      Session.createAnonymous().then(function (sessionData) {
        startShortLibra(sessionData.userId);
      },
      function () {
        Dialogs.alert('Er is een fout opgetreden bij het starten van de LIBRA test.');
        App.isBusy(false);
      });
    }
  },

  // Score of short LIBRA test
  shortLibraScore: function () {
    let model = new CareVariablesModel({userId: Session.userId});
    App.goto(new ShortLibraScoreView({
      model: model
    }));
  },

  startCoachSession: function (id, dailySession) {
    App.isBusy(true);
    if (!Session.isLoggedIn) {
      Dialogs.alert('Je moet ingelogd zijn om een test te doen.');
      App.isBusy(false);
      return;
    }

    // Determine whether to use chat UI or not
    let careVariablesModel = new CareVariablesModel({userId: Session.userId});
    careVariablesModel.fetch({cache: true, expires: 60})
      .done(function (careVariables) {
        let useChatUi = careVariables.getVariableValue('ACL_ToonAlsChat') === 'Ja' || Session.isAnonymous;
        let questionManager = new QuestionManager(id, false);

        if (useChatUi) {
          App.goto(new ChatCoachQuestionView({
            careVariables: careVariables,
            questionManager: questionManager,
            sessionId: id,
            dailySession: dailySession == 1
          }));
        } else {
          // Get first question.
          questionManager.getFirstQuestion()
            .then(
              function (question) {
                App.goto(new CoachQuestionView({
                  model: question,
                  careVariables: careVariables,
                  questionManager: questionManager,
                  sessionId: id,
                  dailySession: dailySession == 1
                }));
              },
              function () {
                Dialogs.alert('Er is een fout opgetreden bij het starten van de test.');
                App.isBusy(false);
              }
            );
        }
      })
      .fail(function () {
        Dialogs.alert('Er is een fout opgetreden bij het starten van de test.');
        App.isBusy(false);
      });
  },

  coachQuestion: function (id, question) {
  },

  viewCoachSession: function (id) {
    if (!Session.isLoggedIn) {
      Dialogs.alert('Je moet ingelogd zijn om een test te bekijken.');
      return;
    }

    App.isBusy(true);

    let questionManager = new QuestionManager(id, true);
    questionManager.getFirstQuestion()
      .then(
        function (question) {
          App.goto(new CoachQuestionView({
            model: question,
            careVariables: null,
            questionManager: questionManager,
            sessionId: id,
            dailySession: true
          }));
        },
        function () {
          Dialogs.alert('Er is een fout opgetreden bij het bekijken van de test.');
          App.isBusy(false);
        }
      );
  },

  // Themes overview
  themes: function () {
    let model = new CareVariablesModel({userId: Session.userId});
    App.goto(new ThemesView({model: model}));
  },

  // Theme single
  themeDetail: function (id) {
    App.goto(new ThemeDetailView({
      id: id,
      model: new PageModel({id: 'theme_' + id})
    }));
  }
};
