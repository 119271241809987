'use strict';

import ContentView from '../views/content';

import App from '../app';

import _ from 'underscore';
import Session from '../utils/session';
import * as Dialogs from '../utils/dialogs';
import FormMixin from '../utils/formmixin.js';
import Validator from 'validatorjs';

import jQuery from 'jquery';

const ERRORCODE_USERNAME_EXISTS = 113;

let RegisterView = ContentView.extend({
  template: 'register',
  isPublic: false,

  ui: {
    form: 'form.user-form',
    initials: '#initials',
    last_name: '#last_name',
    email: '#email',
    birthday: '#birthday',
    username: '#username',
    password: '#password',
    password_confirmation: '#password_confirmation',
    privacy_policy_approved: '#privacy_policy_approved',
    submit: 'button[type="submit"]'
  },

  events: {
    'click @ui.submit': 'submit',
    'blur @ui.username': 'blurUsername'
  },

  onBeforeRender: function (options) {
    this.templateContext = jQuery.extend({
      privacyStatementUrl: App.config.get('privacyStatementUrl')
    }, this.templateContext);
  },

  blurUsername: function (event) {
    // Force lowercase password
    let input = jQuery(event.currentTarget);
    input.val(input.val().toLowerCase());
  },

  submit: function (event) {
    event.preventDefault();

    App.isBusy(true);
    let registerView = this;

    // Get password rules
    Session.getPasswordRules().then(function (passwordRules) {
      // Add password validation rules
      let formValidationRules = registerView.getFormValidationRules(passwordRules);

      // Validate form
      let validation = new Validator(registerView.getFormValues(registerView.ui.form), formValidationRules);
      validation.setAttributeNames(registerView.formValidationAttributeNames);
      if (validation.passes()) {
        // Submit registration
        let registrationData = {
          userId: Session.userId,
          initials: registerView.ui.initials.val(),
          lastname: registerView.ui.last_name.val(),
          birthdate: registerView.ui.birthday.val(),
          email: registerView.ui.email.val(),
          gender: registerView.ui.form.find('[name="gender"]:checked').val(),
          username: registerView.ui.username.val(),
          password: registerView.ui.password.val(),
          gdpr_approved: registerView.ui.privacy_policy_approved.prop('checked')
        };

        Session.request('POST', 'user/anonymous_to_registered', registrationData)
          .done(function (data, status, xhr) {
            // Re-login user
            Session.login(registrationData.username, registrationData.password)
              .then(
                function (loginData) {
                  // Register this as a new version user
                  let variableData = {
                    userId: loginData.userId,
                    variableName: 'ACL_NieuweVersie',
                    variableValue: 'True'
                  };

                  Session.request('POST', 'coach/write_variable', variableData)
                    .done(function (data, status, xhr) {
                      if (!data) {
                        Dialogs.alert('Er is een fout opgetreden bij het registreren.');
                        App.isBusy(false);
                        return;
                      }

                      App.clearCache();
                      App.isBusy(false);
                      App.router.navigate('#snippet', {trigger: true});
                    })
                    .fail(function (xhr, status, error) {
                      Dialogs.alert('Er is een fout opgetreden bij het registreren.');
                      App.isBusy(false);
                    });
                },
                function (httpStatus, error) {
                  Dialogs.alert('Kan niet inloggen.');
                  App.isBusy(false);
                }
              );
          })
          .fail(function (xhr, status, error) {
            if (xhr.responseJSON.errorCode == ERRORCODE_USERNAME_EXISTS) {
              let errorMsg = 'De gekozen gebruikersnaam is niet beschikbaar of voldoet niet aan de eisen.';
              if (typeof xhr.responseJSON.usernameSuggestion !== 'undefined' && xhr.responseJSON.usernameSuggestion) {
                errorMsg += ' Wij hebben een suggestie voor een gebruikersnaam voor je ingevuld.';
                registerView.ui.username.val(xhr.responseJSON.usernameSuggestion);
                registerView.ui.username.focus();
              }

              Dialogs.alert(errorMsg);
            } else {
              // Unknown error
              Dialogs.alert('Kan de registratie niet opslaan: ' + xhr.responseJSON.errorMessage);
            }
            App.isBusy(false);
          });
      } else {
        Dialogs.alert(registerView.getFormDisplayableErrors(validation.errors));
        App.isBusy(false);
      }
    },
    function () {
      Dialogs.alert('Er is een fout opgetreden bij het ophalen van de wachtwoordeisen.');
      App.isBusy(false);
    });

    return false;
  },

  getFormValidationRules: function (passwordRules) {
    let rules = {
      initials: 'required',
      last_name: 'required',
      email: 'required|email',
      gender: 'required',
      birthday: 'required',
      username: 'required',
      password: ['required'],
      privacy_policy_approved: 'required'
    };

    if (passwordRules.minLength) {
      rules.password.push('min:' + passwordRules.minLength);
    }

    if (passwordRules.minDigits) {
      rules.password.push('min_digits:' + passwordRules.minDigits);
    }

    if (passwordRules.minLowers) {
      rules.password.push('min_lowers:' + passwordRules.minLowers);
    }

    if (passwordRules.minUppers) {
      rules.password.push('min_uppers:' + passwordRules.minUppers);
    }

    if (passwordRules.minSpecialChars) {
      rules.password.push('min_specialchars:' + passwordRules.minSpecialChars);
    }

    rules.password.push('confirmed');

    return rules;
  },

  formValidationAttributeNames: {
    initials: 'initialen',
    last_name: 'achternaam',
    email: 'e-mailadres',
    gender: 'geslacht',
    birthday: 'geboortedatum',
    username: 'gebruikersnaam',
    password: 'wachtwoord',
    privacy_policy_approved: 'akkoord'
  }
});

_.extend(RegisterView.prototype, FormMixin);

export default RegisterView;
