'use strict';

import AnsweredQuestionsCollection from '../collections/answered_questions';

import CoachQuestionModel from '../models/coach_question';

import Session from './session';
import jQuery from 'jquery';


let Validator = require('validatorjs');

class QuestionManager {
  /**
   * Constructor.
   */
  constructor (sessionId, isCompletedSession) {
    this.sessionId = sessionId;
    this.isCompletedSession = isCompletedSession;
    this.completedSessionAnswers = null;
    this.answerIndex = 0;
  }

  getFirstQuestion () {
    let self = this;
    return new Promise(function (resolve, reject) {
      if (self.isCompletedSession) {
        self.answerIndex = 0;
        self._getCompletedSessionAnswers()
          .then(
            function (answers) {
              if (answers.length < 1) {
                reject();
              } else {
                resolve(answers.at(self.answerIndex));
              }
            },
            function () {
              reject();
            }
          );
      } else {
        Session.request('POST', 'contentplayer/get_first_question', {'userId': Session.userId, 'sessionId': self.sessionId})
          .done(function (data) {
            if (!data) {
              reject();
              return;
            }

            if (data.sessionEnded || !data.question) {
              reject();
              return;
            }

            let model = new CoachQuestionModel(data.question);
            resolve(model);
          })
          .fail(function () {
            reject();
          });
      }
    });
  }

  getPreviousQuestion () {
    let self = this;
    return new Promise(function (resolve, reject) {
      if (self.isCompletedSession) {
        self.answerIndex--;
        if (self.answerIndex < 0) {
          reject();
        } else {
          self._getCompletedSessionAnswers()
            .then(
              function (answers) {
                if (self.answerIndex > (answers.length - 1)) {
                  resolve(null);
                } else {
                  resolve(answers.at(self.answerIndex));
                }
              },
              function () {
                reject();
              }
            );
        }
      } else {
        Session.request('POST', 'contentplayer/get_previous_question', {'userId': Session.userId, 'sessionId': self.sessionId})
          .done(function (data) {
            if (!data) {
              reject();
              return;
            }

            if (data.sessionEnded || !data.question) {
              resolve(null);
            } else {
              let model = new CoachQuestionModel(data.question);
              resolve(model);
            }
          })
          .fail(function () {
            reject();
          });
      }
    });
  }

  getNextQuestion (answer) {
    let self = this;
    return new Promise(function (resolve, reject) {
      if (self.isCompletedSession) {
        self.answerIndex++;
        self._getCompletedSessionAnswers()
          .then(
            function (answers) {
              if (self.answerIndex > (answers.length - 1)) {
                resolve(null);
              } else {
                resolve(answers.at(self.answerIndex));
              }
            },
            function () {
              reject();
            }
          );
      } else {
        let questionData = {
          'userId': Session.userId,
          'sessionId': self.sessionId,
          'answer': answer.value
        };

        Session.request('POST', 'contentplayer/get_next_question', questionData)
          .done(function (data) {
            if (!data) {
              reject();
              return;
            }

            if (data.sessionEnded || !data.question) {
              resolve(null);
            } else {
              let model = new CoachQuestionModel(data.question);
              resolve(model);
            }
          })
          .fail(function () {
            reject();
          });
      }
    });
  }

  _getCompletedSessionAnswers () {
    let self = this;
    return new Promise(function (resolve, reject) {
      if (self.completedSessionAnswers !== null) {
        resolve(self.completedSessionAnswers);
      } else {
        let collection = new AnsweredQuestionsCollection();
        collection.userId = Session.userId;
        collection.sessionId = self.sessionId;

        collection.fetch()
          .done(function () {
            self.completedSessionAnswers = collection;
            resolve(self.completedSessionAnswers);
          })
          .fail(function () {
            reject();
          });
      }
    });
  }

  getSubmittedAnswerValue (question, form) {
    let answer = {
      error: null,
      displayableValue: [],
      value: {},
    };
    let formData, rules, validation;

    switch (question.get('type')) {
      case QuestionManager.QUESTION_TYPES.message:
        answer.value.questionId = question.get('id');
        answer.displayableValue.push(form.find('.form-submit').val());
        break;

      case QuestionManager.QUESTION_TYPES.multipleChoice:
        formData = {answer: form.find('[name="answer"]:checked').val()};
        if (question.get('required')) {
          rules = {answer: 'required'};
          validation = new Validator(formData, rules);
          validation.setAttributeNames({answer: 'antwoord'});
          if (validation.passes()) {
            answer.value.answerId = formData.answer;
            answer.value.answer = form.find('label[for="answer-' + answer.value.answerId + '"]').text();
            answer.value.questionId = question.get('id');
          } else {
            answer.error = validation.errors.first('answer');
          }
        } else {
          answer.value.answerId = formData.answer;
          answer.value.answer = form.find('label[for="answer-' + answer.value.answerId + '"]').text();
          answer.value.questionId = question.get('id');
        }

        break;

      case QuestionManager.QUESTION_TYPES.number:
      case QuestionManager.QUESTION_TYPES.slider:
      case QuestionManager.QUESTION_TYPES.scale:
        formData = {answer: form.find('[name="answer"]').val()};
        rules = {answer: 'numeric'};

        if (question.get('required')) {
          rules.answer += '|required';
        }
        if (question.get('minValue')) {
          rules.answer += '|min:' + question.get('minValue');
        }
        if (question.get('maxValue')) {
          rules.answer += '|max:' + question.get('maxValue');
        }

        validation = new Validator(formData, rules);
        validation.setAttributeNames({answer: 'antwoord'});
        if (validation.passes()) {
          answer.value.answer = formData.answer;
          answer.value.questionId = question.get('id');
        } else {
          answer.error = validation.errors.first('answer');
        }

        break;

      case QuestionManager.QUESTION_TYPES.text:
        formData = {answer: form.find('[name="answer"]').val()};
        rules = {answer: []};

        if (question.get('required')) {
          rules.answer.push('required');
        }
        if (question.get('maxLength')) {
          rules.answer.push('max:' + question.get('maxLength'));
        }

        if (rules.answer.length > 0) {
          validation = new Validator(formData, rules);
          validation.setAttributeNames({answer: 'antwoord'});
          if (validation.passes()) {
            answer.value.answer = formData.answer;
            answer.value.questionId = question.get('id');
          } else {
            answer.error = validation.errors.first('answer');
          }
        } else {
          answer.value.answer = formData.answer;
          answer.value.questionId = question.get('id');
        }

        break;

      case QuestionManager.QUESTION_TYPES.date:
        let inputDate = form.find('[name="answer"]').val();
        formData = {answer: QuestionManager._transformDateFormatToApi(inputDate)};
        rules = {answer: []};

        if (question.get('required')) {
          rules.answer.push('required');
        }

        if (rules.answer.length > 0) {
          validation = new Validator(formData, rules);
          validation.setAttributeNames({answer: 'antwoord'});
          if (validation.passes()) {
            answer.value.answer = formData.answer;
            answer.value.questionId = question.get('id');
            answer.displayableValue.push(inputDate);
          } else {
            answer.error = validation.errors.first('answer');
          }
        } else {
          answer.value.answer = formData.answer;
          answer.value.questionId = question.get('id');
          answer.displayableValue.push(inputDate);
        }

        break;

      case QuestionManager.QUESTION_TYPES.multiSelect:
        let answerIds = [];
        let answerTexts = [];
        let answerDisplayableTexts = [];
        form.find('[name="answer"]:checked').each(function () {
          let answerId = jQuery(this).val();
          let answerText = form.find('label[for="answer-' + answerId + '"]').text();
          answerIds.push(answerId);
          answerTexts.push(answerId + '|' + answerText);
          answerDisplayableTexts.push(answerText);
        });
        formData = {answer: answerIds.join('|')};
        rules = {answer: []};

        if (question.get('minValue')) {
          rules.answer.push('min_selected:' + question.get('minValue'));
        }
        if (question.get('maxValue')) {
          rules.answer.push('max_selected:' + question.get('maxValue'));
        }

        if (rules.answer.length > 0) {
          validation = new Validator(formData, rules);
          validation.setAttributeNames({answer: 'antwoord'});
          if (validation.passes()) {
            answer.value.answer = answerTexts.join('|');
            answer.value.questionId = question.get('id');
            answer.displayableValue = answerDisplayableTexts;
          } else {
            answer.error = validation.errors.first('answer');
          }
        } else {
          answer.value.answer = answerTexts.join('|');
          answer.value.questionId = question.get('id');
          answer.displayableValue = answerDisplayableTexts;
        }

        break;
    }

    if (answer.displayableValue.length === 0) {
      answer.displayableValue.push(answer.value.answer);
    }

    return answer;
  }

  static _transformDateFormatToApi(date) {
    if (date) {
      // We get the date in ISO-8601 format, convert to YYYYMMDD.
      let reg = date.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/);
      if (reg) {
        return reg[1] + reg[2] + reg[3];
      }
    }

    return null;
  }
}

/**
 * Question types
 */
QuestionManager.QUESTION_TYPES = {
  'message': 1,
  'multipleChoice': 2,
  'number': 3,
  'slider': 4,
  'scale': 5,
  'text': 6,
  'date': 10,
  'multiSelect': 11,
};

export default QuestionManager;
