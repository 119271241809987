'use strict';

import ContentView from './content';
import Session from '../utils/session';
import App from '../app';
import jQuery from 'jquery';
import * as ChatUi from '../utils/chatui';
import * as Templates from '../templates';
import * as Dialogs from '../utils/dialogs';
import QuestionManager from '../utils/questionmanager';
import Backbone from 'backbone';

const REACTION_TIMEOUT = 1000;
const TYPING_TIMEOUT = 1800;
const INPUT_TIMEOUT = 1500;

let ChatCoachQuestionView = ContentView.extend({
  template: 'chat_coach_question',
  isPublic: false,

  question: null,

  ui: {
    chatLog: '.chat-container .chat-log',
    prevSubmit: '.chat-container .chat-message__navigation a.previous-question',
    nextSubmit: '.chat-container .form-submit',
    radioButton: '.chat-container input[type="radio"]',
    cancel: '.chat-container .chat-message__navigation a.cancel'
  },

  events: {
    'click @ui.prevSubmit': 'prevSubmit',
    'click @ui.nextSubmit': 'nextSubmit',
    'click @ui.radioButton': 'nextSubmit',
    'click @ui.cancel': 'cancel'
  },

  cancel: function (event) {
    event.preventDefault();

    if (Session.isLoggedIn && !Session.isAnonymous) {
      App.router.navigate('snippet', {trigger: true});
    } else {
      App.router.navigate('', {trigger: true});
    }

    return false;
  },

  onBeforeRender: function (options) {
    let currentTheme = '';
    if (this.getOption('careVariables')) {
      let themeValue = this.getOption('careVariables').getVariableValue('ACL_GekozenThema');
      if (themeValue) {
        let theme = App.config.getThemeByValue(themeValue);
        if (theme) {
          currentTheme = theme.title;
        }
      }
    }

    this.templateContext = jQuery.extend({}, this.templateContext, {
      loggedIn: Session.isLoggedIn && !Session.isAnonymous,
      isDailySession: this.getOption('dailySession'),
      currentTheme: currentTheme
    });
  },

  onRender: function (options) {
    if (Session.isAnonymous) {
      App.refreshHeader({showHeader: false});
    }

    ChatUi.addLoadingMessage(this.ui.chatLog);

    let view = this;
    window.setTimeout(function() {
      // Get first question.
      let questionManager = view.getOption('questionManager');
      questionManager.getFirstQuestion()
        .then(
          function (question) {
            view.question = question;
            view.renderQuestion();
          },
          function () {
            ChatUi.removeLoadingMessage(view.ui.chatLog);
            Dialogs.alert('Er is een fout opgetreden bij het starten van de test.');
          }
        );
    }, TYPING_TIMEOUT);
  },

  renderQuestion: function () {
    let templateContext = jQuery.extend({}, this.templateContext, this.question.attributes);
    let questionClass = this.getQuestionIdClass(this.question);

    // Render message text.
    let message = Templates['chat_question_message'](templateContext);
    let messageClasses = [questionClass];
    if (message.indexOf('responsive-embed') !== -1) {
      messageClasses.push('chat-message--video');
    }
    ChatUi.replaceLoadingMessage(this.ui.chatLog, message, messageClasses);

    this.$el.find('.chat-container').fitVids();

    let view = this;
    window.setTimeout(function() {
      // Render question input.
      let question = Templates['chat_question_input'](templateContext);
      let questionClasses = [
        'chat-message--input',
        'chat-message--question',
        questionClass,
        'chat-message--question-' + ChatCoachQuestionView.QUESTION_TYPE_CLASSES[view.question.get('type')]
      ];
      ChatUi.addClientMessage(view.ui.chatLog, question, questionClasses);

      if (view.shouldShowSubmitButton()) {
        // Render submit button.
        let submit = Templates['chat_question_submit'](templateContext);
        let submitClasses = ['chat-message--input', 'chat-message--submit', questionClass];
        ChatUi.addClientMessage(view.ui.chatLog, submit, submitClasses);
      }

      view.$el.find('.chat-container input[type="range"]').rangeslider({
        rangeClass: 'rangeslider',
        polyfill: false
      });

      view.updateMessageNavigation();
    }, INPUT_TIMEOUT);
  },

  prevSubmit: function (event) {
    event.preventDefault();

    if (!this.question.get('isFirst')) {
      let view = this;
      ChatUi.addLoadingMessage(view.ui.chatLog);

      window.setTimeout(function () {
        let questionManager = view.getOption('questionManager');
        questionManager.getPreviousQuestion()
          .then(
            function (question) {
              if (question === null) {
                Backbone.fetchCache.clearItem('coach/get_care_variables?userId=' + encodeURIComponent(Session.userId));
                Backbone.fetchCache.clearItem('contentplayer/get_answered_scheduled_sessions?{"userId":"' + Session.userId + '"}');
                if (Session.isAnonymous) {
                  ChatUi.removeLoadingMessage(view.ui.chatLog);
                  App.router.navigate('#short-libra-score', {trigger: true});
                } else {
                  Backbone.fetchCache.clearItem('coach/get_active_sessions?userId=' + encodeURIComponent(Session.userId));
                  ChatUi.removeLoadingMessage(view.ui.chatLog);
                  App.router.navigate('#snippet', {trigger: true});
                }
              } else {
                // Remove current question and answer.
                ChatUi.removeMessage(view.ui.chatLog, '.' + view.getQuestionIdClass(view.question));

                view.question = question;

                // Remove previous question and answer and re-render it.
                ChatUi.removeMessage(view.ui.chatLog, '.' + view.getQuestionIdClass(view.question));

                view.renderQuestion();
              }
            },
            function () {
              ChatUi.removeLoadingMessage(view.ui.chatLog);
              Dialogs.alert('Er is een fout opgetreden bij het navigeren.');
            }
          );
      }, TYPING_TIMEOUT);
    }

    return false;
  },

  nextSubmit: function (event) {
    event.preventDefault();

    let questionManager = this.getOption('questionManager');
    let answer = questionManager.getSubmittedAnswerValue(this.question, this.$el);
    if (answer.error) {
      Dialogs.alert(answer.error);
      return false;
    }

    this.setAnsweredQuestion(this.question, answer);

    let view = this;
    window.setTimeout(function () {
      ChatUi.addLoadingMessage(view.ui.chatLog);

      window.setTimeout(function () {
        questionManager.getNextQuestion(answer)
          .then(
            function (question) {
              if (question === null) {
                Backbone.fetchCache.clearItem('coach/get_care_variables?userId=' + encodeURIComponent(Session.userId));
                Backbone.fetchCache.clearItem('contentplayer/get_answered_scheduled_sessions?{"userId":"' + Session.userId + '"}');
                if (Session.isAnonymous) {
                  ChatUi.removeLoadingMessage(view.ui.chatLog);
                  App.router.navigate('#short-libra-score', {trigger: true});
                } else {
                  Backbone.fetchCache.clearItem('coach/get_active_sessions?userId=' + encodeURIComponent(Session.userId));
                  ChatUi.removeLoadingMessage(view.ui.chatLog);
                  App.router.navigate('#snippet', {trigger: true});
                }
              } else {
                view.question = question;
                view.renderQuestion();
              }
            },
            function () {
              ChatUi.removeLoadingMessage(view.ui.chatLog);
              Dialogs.alert('Er is een fout opgetreden bij het versturen van het antwoord.');
            }
          );
      }, TYPING_TIMEOUT);
    }, REACTION_TIMEOUT);

    return false;
  },

  setAnsweredQuestion: function (question, answer) {
    ChatUi.removeMessage(this.ui.chatLog, '.chat-message--submit');

    let answerMessage = Templates['chat_question_answer']({answers: answer.displayableValue});
    let answerClasses = [this.getQuestionIdClass(question)];
    ChatUi.replaceClientMessage(this.ui.chatLog, '.chat-message--question', answerMessage, answerClasses);
  },

  shouldShowSubmitButton: function () {
    return this.question.get('type') !== QuestionManager.QUESTION_TYPES.message &&
      this.question.get('type') !== QuestionManager.QUESTION_TYPES.multipleChoice;
  },

  updateMessageNavigation: function () {
    let messageNavigation = this.$el.find('.chat-container .chat-message__navigation');
    let previousQuestionShown = true;
    let cancelShown = true;
    if (this.question.get('isFirst')) {
      messageNavigation.find('.previous-question').hide();
      previousQuestionShown = false;
    } else {
      messageNavigation.find('.previous-question').show();
    }

    if (Session.isLoggedIn && !Session.isAnonymous) {
      messageNavigation.find('.cancel').hide();
      cancelShown = false;
    } else {
      messageNavigation.find('.cancel').show();
    }

    if (previousQuestionShown && cancelShown) {
      messageNavigation.find('span').show();
      messageNavigation.show();
    } else if (previousQuestionShown || cancelShown) {
      messageNavigation.find('span').hide();
      messageNavigation.show();
    } else {
      messageNavigation.hide();
    }
  },

  getQuestionIdClass: function (question) {
    return 'chat-message--question-' + question.get('id');
  }
});

/**
 * Question type classes
 */
ChatCoachQuestionView.QUESTION_TYPE_CLASSES = {
  1: 'message',
  2: 'multiple-choice',
  3: 'number',
  4: 'slider',
  5: 'scale',
  6: 'text',
  10: 'date',
  11: 'multi-select',
};

export default ChatCoachQuestionView;
