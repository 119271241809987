import Handlebars from "handlebars"; Handlebars.registerPartial("chatui_navigation", Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"chat-message__navigation\">\n  <a href=\"#\" class=\"previous-question\">Vorige vraag</a>\n  <span>|</span>\n  <a href=\"#\" class=\"cancel\">Sluiten</a>\n</div>";
},"useData":true}));
Handlebars.registerPartial("loader", Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"loading-container in-content\">\n  <div class=\"loading\"></div>\n</div>\n";
},"useData":true}));
Handlebars.registerPartial("question_date", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return container.escapeExpression(((helper = (helper = helpers.valueToRestore || (depth0 != null ? depth0.valueToRestore : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"valueToRestore","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return container.escapeExpression(((helper = (helper = helpers.defaultValue || (depth0 != null ? depth0.defaultValue : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"defaultValue","hash":{},"data":data}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    return " required";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"form-item form-type-date\">\n  <input class=\"form-type-date\" name=\"answer\" lang=\"nl_nl\" date-format=\"dd-mm-yyyy\" placeholder=\"Datum\" type=\"date\" value=\""
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.valueToRestore : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\""
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.required : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n</div>";
},"useData":true}));
Handlebars.registerPartial("question_multiple_choice", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "form-radios-2";
},"3":function(container,depth0,helpers,partials,data) {
    return "form-radios-multiple";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "    <div class=\"form-item form-type-radio\">\n      <input type=\"radio\" id=\"answer-"
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" name=\"answer\" value=\""
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"form-radio\""
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.checked : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      <label class=\"option\" for=\"answer-"
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"text","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</label>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"form-radios "
    + ((stack1 = (helpers.ifeq||(depth0 && depth0.ifeq)||container.hooks.helperMissing).call(alias2,((stack1 = (depth0 != null ? depth0.answers : depth0)) != null ? stack1.length : stack1),2,{"name":"ifeq","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.answers : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true}));
Handlebars.registerPartial("question_multiselect", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "    <div class=\"form-item form-type-checkbox\">\n      <input type=\"checkbox\" id=\"answer-"
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" name=\"answer\" value=\""
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"form-checkbox\""
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.checked : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      <label class=\"option\" for=\"answer-"
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"text","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</label>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "<div class=\"form-checkboxes\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.answers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true}));
Handlebars.registerPartial("question_number", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return container.escapeExpression(((helper = (helper = helpers.valueToRestore || (depth0 != null ? depth0.valueToRestore : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"valueToRestore","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return " required";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return " placeholder=\""
    + container.escapeExpression(((helper = (helper = helpers.defaultValue || (depth0 != null ? depth0.defaultValue : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"defaultValue","hash":{},"data":data}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"form-item form-type-textfield\">\n  <input type=\"number\" class=\"form-type-text\" name=\"answer\" value=\""
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.valueToRestore : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\""
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.required : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.defaultValue : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n</div>";
},"useData":true}));
Handlebars.registerPartial("question_slider", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return container.escapeExpression(((helper = (helper = helpers.valueToRestore || (depth0 != null ? depth0.valueToRestore : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"valueToRestore","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return container.escapeExpression(((helper = (helper = helpers.defaultValue || (depth0 != null ? depth0.defaultValue : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"defaultValue","hash":{},"data":data}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    return " required";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "<div class=\"form-item form-type-range\">\n  <input type=\"range\" class=\"form-type-range\" name=\"answer\" min=\""
    + alias5(((helper = (helper = helpers.minValue || (depth0 != null ? depth0.minValue : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"minValue","hash":{},"data":data}) : helper)))
    + "\" max=\""
    + alias5(((helper = (helper = helpers.maxValue || (depth0 != null ? depth0.maxValue : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"maxValue","hash":{},"data":data}) : helper)))
    + "\" step=\"1\" value=\""
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.valueToRestore : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\""
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.required : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n  <span class=\"range-values\">\n    <span class=\"min\">"
    + alias5(((helper = (helper = helpers.minText || (depth0 != null ? depth0.minText : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"minText","hash":{},"data":data}) : helper)))
    + "</span>\n    <span class=\"max\">"
    + alias5(((helper = (helper = helpers.maxText || (depth0 != null ? depth0.maxText : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"maxText","hash":{},"data":data}) : helper)))
    + "</span>\n  </span>\n</div>";
},"useData":true}));
Handlebars.registerPartial("question_text", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <textarea name=\"answer\" rows=\"5\" class=\"form-type-textarea\""
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.required : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.valueToRestore : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "</textarea>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " required";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return container.escapeExpression(((helper = (helper = helpers.valueToRestore || (depth0 != null ? depth0.valueToRestore : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"valueToRestore","hash":{},"data":data}) : helper)));
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return container.escapeExpression(((helper = (helper = helpers.defaultValue || (depth0 != null ? depth0.defaultValue : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"defaultValue","hash":{},"data":data}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <input type=\"text\" class=\"form-type-text\" name=\"answer\" value=\""
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.valueToRestore : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\""
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.required : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "<div class=\"form-item form-type-textfield\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.multiline : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true}));