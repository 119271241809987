import App from './app';

export default {
  'new-session': function (data) {
    App.router.navigate('#snippet', {trigger: true});
  },
  'select-theme': function (data) {
    App.router.navigate('#themes', {trigger: true});
  }
};
