'use strict';

import Backbone from 'backbone';

import SessionModel from '../models/session';

export default Backbone.Collection.extend({
  model: SessionModel,

  url: function () {
    return 'contentplayer/get_answered_scheduled_sessions';
  },

  fetch: function (options) {
    options = options || {};
    options.type = 'POST';
    options.contentType = 'application/json';
    options.data = JSON.stringify({
      'userId': this.userId
    });
    return this.constructor.__super__.fetch.call(this, options);
  },

  /**
   * Sort on completion date descending.
   *
   * @param a
   * @param b
   * @returns {number}
   */
  comparator: function (a, b) {
    if (a.get('dateOfCompletion') < b.get('dateOfCompletion')) {
      return 1;
    } else if (a.get('dateOfCompletion') > b.get('dateOfCompletion')) {
      return -1;
    }
    return 0;
  },

  userId: ''
});
