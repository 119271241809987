'use strict';

import Backbone from 'backbone';

import DateUtil from '../utils/dateutil';

export default Backbone.Model.extend({

  parse: function (response, options) {
    if (response.hasOwnProperty('sessionId')) {
      // Data is in the format returned by contentplayer/get_answered_scheduled_sessions.
      return {
        id: response.sessionId,
        title: response.dialogName,
        dateOfCompletion: DateUtil.parseApiDateTime(response.dateOfCompletion)
      };
    }

    return response;
  },

  defaults: {
    allowBackBrowsing: true,
    id: '',
    title: '',
    trajectTitle: '',
    type: 0,
    isDailySession: false,
    dateOfCompletion: null
  }
});
