'use strict';

import ContentView from '../views/content';

import CareVariablesModel from '../models/care_variables';

import Session from "../utils/session";

import App from '../app';
import jQuery from 'jquery';
import * as Dialogs from "../utils/dialogs";
import Backbone from 'backbone';

export default ContentView.extend({
  template: 'theme_detail',
  isPublic: false,
  careVariablesModel: null,

  ui: {
    activateThemeButton: '.thema-status .btn'
  },

  events: {
    'click @ui.activateThemeButton': 'clickActivateTheme'
  },

  initialize: function() {
    this.careVariablesModel = new CareVariablesModel({userId: Session.userId});
    this.listenTo(this.model, 'sync', this.updateVariables);
    this.listenTo(this.careVariablesModel, 'sync', this.update);
    this.model.fetch({cache: true, expires: 60 * 60});
  },

  onRender: function () {
    App.refreshHeader({activeTab: 'themes'});
  },

  updateVariables: function () {
    this.careVariablesModel.fetch({cache: true, expires: 60});
  },

  update: function () {
    this.templateContext = jQuery.extend({}, this.templateContext, this.getTemplateVarsFromModel());
    this.render();
  },

  getTemplateVarsFromModel: function () {
    let currentThemeId = this.careVariablesModel.getVariableValue('ACL_GekozenThema');
    let theme = App.config.getThemeByName(this.getOption('id'));
    let values = {
      icon: '',
      title: '',
      description: this.model.get('intro'),
      long_description: this.model.get('content'),
      active: currentThemeId !== null && theme !== null && currentThemeId == theme.value
    };

    if (theme) {
      values.icon = theme.icon;
      values.title = theme.title;
    }

    return values;
  },

  clickActivateTheme: function (event) {
    event.preventDefault();

    let theme = App.config.getThemeByName(this.getOption('id'));
    if (theme) {
      let variableData = {
        userId: Session.userId,
        variableName: 'ACL_GekozenThema',
        variableValue: theme.id
      };
      let view = this;

      App.isBusy(true);
      Session.request('POST', 'coach/write_variable', variableData)
        .done(function (data, status, xhr) {
          if (!data) {
            Dialogs.alert('Er is een fout opgetreden bij het instellen van het thema.');
            App.isBusy(false);
            return;
          }

          Backbone.fetchCache.clearItem('coach/get_active_sessions?userId=' + encodeURIComponent(Session.userId));
          Backbone.fetchCache.clearItem('coach/get_care_variables?userId=' + encodeURIComponent(Session.userId));

          App.isBusy(false);

          view.templateContext['justActivated'] = true;
          view.careVariablesModel.fetch({cache: false});
        })
        .fail(function (xhr, status, error) {
          Dialogs.alert('Er is een fout opgetreden bij het instellen van het thema.');
          App.isBusy(false);
        });
    }

    return false;
  }

});
