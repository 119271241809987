'use strict';

import ContentView from '../views/content';

import _ from 'underscore';
import Session from '../utils/session';
import * as Dialogs from '../utils/dialogs';
import BackMixin from '../utils/backmixin.js';

import App from '../app';

let LoginView = ContentView.extend({
  template: 'login',
  isPublic: true,

  ui: {
    username: '#username',
    password: '#password',
    submit: 'button[type="submit"]',
    back: '.back-link'
  },

  events: {
    'click @ui.submit': 'submit',
    'click @ui.back': 'back'
  },

  onRender: function (options) {
    App.refreshHeader({showBackButton: true});

    let username = '';
    let storageUsername = window.localStorage.getItem('username');
    if (Session.username) {
      username = Session.username;

    // Fallback for when the username is only stored in old localStorage key.
    } else if (storageUsername) {
      username = storageUsername;
    }

    this.ui.username.val(username);
  },

  submit: function (event) {
    event.preventDefault();

    let username = this.ui.username.val();
    let password = this.ui.password.val();

    if (!username || !password) {
      Dialogs.alert('Voer een gebruikersnaam en wachtwoord in.');
      return;
    }

    App.isBusy(true);
    Session.login(username, password)
      .then(
        function (loginData) {
          Session.setItem('username', username);

          if (window.FirebasePlugin) {
            window.FirebasePlugin.hasPermission(function (hasPermission) {
              if (!hasPermission) {
                window.FirebasePlugin.grantPermission();
              }
            });
          }

          App.clearCache();
          App.isBusy(false);
          if (loginData.passwordUpdateRequired) {
            App.router.navigate('#change-password/1', {trigger: true});
          } else if (!loginData.hasApprovedGdpr) {
            App.router.navigate('#approve-privacy-policy', {trigger: true});
          } else {
            App.router.navigate('#snippet', {trigger: true});
          }
        },
        function (httpStatus, error) {
          if (httpStatus === 401) {
            Dialogs.alert('De gebruikersnaam of het wachtwoord zijn onjuist.');
          } else if (httpStatus === 403) {
            Dialogs.alert('De ingevoerde gebruiker is geblokkeerd.');
          } else {
            Dialogs.alert('Inloggen is mislukt. Controleer de internetverbinding en probeer het opnieuw.');
          }
          App.isBusy(false);
        }
      );
  }
});

_.extend(LoginView.prototype, BackMixin);

export default LoginView;
