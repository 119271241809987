'use strict';

import Backbone from 'backbone';

import CoachQuestionModel from '../models/coach_question';

export default Backbone.Collection.extend({
  model: CoachQuestionModel,

  url: function () {
    return 'contentplayer/get_answered_questions';
  },

  fetch: function (options) {
    options = options || {};
    options.type = 'POST';
    options.contentType = 'application/json';
    options.data = JSON.stringify({
      'userId': this.userId,
      'sessionId': this.sessionId
    });
    return this.constructor.__super__.fetch.call(this, options);
  },

  userId: '',
  sessionId: ''
});
