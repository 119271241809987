'use strict';

export default {
  months: [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December'
  ],

  getFullMonth: function (date) {
    return this.months[date.getMonth()];
  },

  formatDateFullMonth: function (date) {
    return date.getDate() + ' ' + this.getFullMonth(date).toLowerCase() + ' ' + date.getFullYear();
  },

  /**
   * Parses a datetime in YYYYMMDD HH:MM:SS format.
   *
   * @param str
   * @returns {null|Date}
   */
  parseApiDateTime: function (str) {
    let reg = str.match(/^([0-9]{4})([0-9]{2})([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})/);
    if (reg) {
      return new Date(
        parseInt(reg[1], 10),
        parseInt(reg[2], 10) - 1,
        parseInt(reg[3], 10),
        parseInt(reg[4], 10),
        parseInt(reg[5], 10),
        parseInt(reg[6],10),
        0
      );
    }
    return null;
  }
};
