'use strict';

import ContentView from '../views/content';

import App from '../app';

import Session from '../utils/session';
import * as Dialogs from '../utils/dialogs';

import jQuery from 'jquery';
import Backbone from "backbone";

let CoachQuestionView = ContentView.extend({
  template: 'coach_question',
  isPublic: false,

  ui: {
    prevSubmit: '.controls .btn-prev.form-submit',
    nextSubmit: '.controls .btn-next.form-submit',
    cancel: '.cancel a',
    message: '.message',
    rangeQuestions: '.answers input[type="range"]',
    radioButtons: '.answers input[type="radio"]',
    checkBoxes: '.answers input[type="checkbox"]'
  },

  events: {
    'click @ui.prevSubmit': 'previousSubmit',
    'click @ui.nextSubmit': 'nextSubmit',
    'click @ui.cancel': 'cancel',
    'change @ui.radioButtons': 'selectRadioButton',
    'change @ui.checkBoxes': 'selectCheckbox'
  },

  previousSubmit: function (event) {
    event.preventDefault();
    let questionManager = this.getOption('questionManager');

    if (!this.model.get('isFirst')) {
      let view = this;
      App.isBusy(true);
      questionManager.getPreviousQuestion()
        .then(
          function (question) {
            if (question === null) {
              // No further questions, test has ended.
              Backbone.fetchCache.clearItem('coach/get_care_variables?userId=' + encodeURIComponent(Session.userId));
              Backbone.fetchCache.clearItem('contentplayer/get_answered_scheduled_sessions?{"userId":"' + Session.userId + '"}');
              if (Session.isAnonymous) {
                App.isBusy(false);
                App.router.navigate('#short-libra-score', {trigger: true});
              } else {
                Backbone.fetchCache.clearItem('coach/get_active_sessions?userId=' + encodeURIComponent(Session.userId));
                App.isBusy(false);
                App.router.navigate('#snippet', {trigger: true});
              }
              return;
            }

            App.router.navigate('session/' + view.getOption('sessionId') + '/' + question.get('id'));
            App.goto(new CoachQuestionView({
              model: question,
              careVariables: view.getOption('careVariables'),
              questionManager: questionManager,
              sessionId: view.getOption('sessionId'),
              dailySession: view.getOption('dailySession')
            }), true);
          },
          function () {
            Dialogs.alert('Er is een fout opgetreden bij het navigeren.');
            App.isBusy(false);
          }
        );
    } else if (this.model.get('isAnswered')) {
      App.router.navigate('snippet', {trigger: true});
    }

    return false;
  },

  nextSubmit: function (event) {
    event.preventDefault();
    let questionManager = this.getOption('questionManager');

    let answer = questionManager.getSubmittedAnswerValue(this.model, this.$el);
    if (answer.error) {
      Dialogs.alert(answer.error);
      return false;
    }

    let view = this;

    App.isBusy(true);
    questionManager.getNextQuestion(answer)
      .then(
        function (question) {
          if (question === null) {
            // No further questions, test has ended.
            Backbone.fetchCache.clearItem('coach/get_care_variables?userId=' + encodeURIComponent(Session.userId));
            Backbone.fetchCache.clearItem('contentplayer/get_answered_scheduled_sessions?{"userId":"' + Session.userId + '"}');
            if (Session.isAnonymous) {
              App.isBusy(false);
              App.router.navigate('#short-libra-score', {trigger: true});
            } else {
              Backbone.fetchCache.clearItem('coach/get_active_sessions?userId=' + encodeURIComponent(Session.userId));
              App.isBusy(false);
              App.router.navigate('#snippet', {trigger: true});
            }
            return;
          }

          App.router.navigate('session/' + view.getOption('sessionId') + '/' + question.get('id'));
          App.goto(new CoachQuestionView({
            model: question,
            careVariables: view.getOption('careVariables'),
            questionManager: questionManager,
            sessionId: view.getOption('sessionId'),
            dailySession: view.getOption('dailySession')
          }), false);
        },
        function () {
          Dialogs.alert('Er is een fout opgetreden bij het versturen van het antwoord.');
          App.isBusy(false);
        }
      );

    return false;
  },

  cancel: function (event) {
    event.preventDefault();

    if (Session.isLoggedIn && !Session.isAnonymous) {
      App.router.navigate('#snippet', {trigger: true});
    } else {
      App.router.navigate('', {trigger: true});
    }

    return false;
  },

  onBeforeRender: function (options) {
    let currentTheme = '';
    if (this.getOption('careVariables')) {
      let themeValue = this.getOption('careVariables').getVariableValue('ACL_GekozenThema');
      if (themeValue) {
        let theme = App.config.getThemeByValue(themeValue);
        if (theme) {
          currentTheme = theme.title;
        }
      }
    }

    this.templateContext = jQuery.extend({}, this.templateContext, {
      loggedIn: Session.isLoggedIn && !Session.isAnonymous,
      isDailySession: this.getOption('dailySession'),
      currentTheme: currentTheme
    });
  },

  onRender: function (options) {
    if (Session.isAnonymous) {
      App.refreshHeader({showHeader: false});
    }

    let checkedRadios = this.ui.radioButtons.filter(':checked');
    if (checkedRadios.length > 0) {
      this.ui.radioButtons.not(checkedRadios).addClass('unselected');
    }

    if (this.model.get('isFirst') && !this.model.get('isAnswered')) {
      this.ui.prevSubmit.prop('disabled', true);
    } else {
      this.ui.prevSubmit.prop('disabled', false);
    }

    if (this.model.get('required') && (this.ui.radioButtons.length > 0 || this.ui.checkBoxes.length > 0)) {
      if (checkedRadios.length > 0 || this.ui.checkBoxes.is(':checked')) {
        this.ui.nextSubmit.prop('disabled', false);
      } else {
        this.ui.nextSubmit.prop('disabled', true);
      }
    } else {
      this.ui.nextSubmit.prop('disabled', false);
    }
  },

  onAttach: function () {
    this.ui.rangeQuestions.rangeslider({
      rangeClass: 'rangeslider',
      polyfill: false
    });

    this.ui.message.fitVids();
  },

  selectRadioButton: function (event) {
    jQuery(':radio[name=' + event.currentTarget.name + ']').addClass('unselected');
    jQuery(event.currentTarget).removeClass('unselected');
    this.ui.nextSubmit.prop('disabled', false);
  },

  selectCheckbox: function (event) {
    if (this.model.get('required')) {
      this.ui.nextSubmit.prop('disabled', !this.ui.checkBoxes.is(':checked'));
    }
  }

});

export default CoachQuestionView;
