'use strict';

import ContentView from '../views/content';

import SessionCollection from '../collections/sessions';
import AnsweredSessionsCollection from '../collections/answered_sessions';

import jQuery from 'jquery';
import DateUtil from '../utils/dateutil';
import Session from '../utils/session';
import App from '../app';
import CareVariablesModel from "../models/care_variables";

export default ContentView.extend({
  template: 'snippet',
  isPublic: false,

  audioElement: null,
  audioHasPlayed: false,

  careVariablesModel: null,
  answeredSessionsCollection: null,

  ui: {
    startSnippetLink: '.noot-link',
    nootFull: '.noot-full',
    themesPageLink: '.themes-page-link'
  },

  events: {
    'click @ui.startSnippetLink': 'startSnippet',
    'click @ui.themesPageLink': 'gotoThemes'
  },

  initialize: function (options) {
    this.collection = new SessionCollection();
    this.collection.userId = Session.userId;
    this.listenTo(this.collection, 'sync', this.updateVariables);
    this.collection.fetch({cache: true, expires: 60});

    this.careVariablesModel = new CareVariablesModel({userId: Session.userId});
    this.listenTo(this.careVariablesModel, 'sync', this.updateAnswered);

    this.answeredSessionsCollection = new AnsweredSessionsCollection();
    this.answeredSessionsCollection.userId = Session.userId;
    this.listenTo(this.answeredSessionsCollection, 'sync', this.update);

    let view = this;

    if (Modernizr.audio) {
      this.audioElement = document.createElement('audio');
      this.audioElement.setAttribute('src', 'media/walnut.mp3');
      this.audioElement.addEventListener('ended', function() {
        view.audioHasPlayed = true;
        view.ui.nootFull.addClass('has-played');
        view.ui.startSnippetLink.trigger('click');
      });
    }
  },

  onRender: function () {
    App.refreshHeader({activeTab: 'snippet'});
  },

  updateVariables: function () {
    this.careVariablesModel.fetch({cache: true, expires: 60});
  },

  updateAnswered: function () {
    this.answeredSessionsCollection.fetch({cache: true, expires: 60});
  },

  update: function () {
    this.templateContext = jQuery.extend({}, this.templateContext, this.getTemplateVarsFromModel());
    this.render();
  },

  startSnippet: function (event) {
    event.preventDefault();

    if (this.audioElement && !this.audioHasPlayed) {
      if (navigator.vibrate) {
        setTimeout(function() {
          navigator.vibrate([150, 200, 50]);
        }, 500);
      }

      this.ui.nootFull.addClass('is-playing');

      this.audioElement.play();
      return false;
    }

    if (this.collection.length > 0) {
      let model = this.collection.at(0);
      App.router.navigate('session/' + model.get('id') + '/' + (model.get('isDailySession') ? 1 : 0), {trigger: true});
    }

    return false;
  },

  gotoThemes: function (event) {
    event.preventDefault();

    App.router.navigate('themes', {trigger: true});

    return false;
  },

  getTemplateVarsFromModel: function () {
    let now = new Date();
    let data = {
      loaded: this.careVariablesModel.get('variables').length > 0,
      day: now.getDate(),
      month: DateUtil.getFullMonth(now),
      hasSnippet: false,
      theme: '',
      themeIcon: '',
      completedSessions: [],
      bonus: 0,
      firstReward: false,
      completed: false,
      rewardTotalCount: App.config.get('totalRewardCount'),
      rewardCompletedCount: 0
    };
    if (this.collection.length > 0) {
      data.hasSnippet = true;
    } else {
      let themeValue = this.careVariablesModel.getVariableValue('ACL_GekozenThema');
      if (themeValue) {
        let theme = App.config.getThemeByValue(themeValue);
        if (theme) {
          data.theme = theme.title;
          data.themeIcon = theme.icon;
          data.rewardCompletedCount = this.careVariablesModel.getVariableValue(theme.rewardVariable);
          if (data.rewardCompletedCount === null) {
            data.rewardCompletedCount = 1;
          }
          data.bonus = parseInt(this.careVariablesModel.getVariableValue(theme.bonusVariable), 10);
          if (isNaN(data.bonus)) {
            data.bonus = 0;
          }
          data.completedSessions = this.getCompletedSessions();
          data.firstReward = data.rewardCompletedCount == 1;
        }
      }
    }

    return data;
  },

  getCompletedSessions: function () {
    let completedSessions = [];
    for (let i = 0; i < this.answeredSessionsCollection.length; i++) {
      let session = this.answeredSessionsCollection.at(i);
      let dateOfCompletion = session.get('dateOfCompletion');
      completedSessions.push({
        'id': session.get('id'),
        'title': session.get('title'),
        'completedDate': dateOfCompletion ? DateUtil.formatDateFullMonth(dateOfCompletion) : '',
        'imageNr': (i % 4) + 1,
      });
    }

    return completedSessions;
  }
});
